import React, { Component, createRef } from 'react'
import StreamPlayer from './StreamPlayer'

class Entity extends Component {
  constructor(props) {
    super(props)

    this.state = {
      stream: null,
      position: { x: null, y: null },
    }

    this.idRef = createRef()

    const { peer } = props

    peer.on('stream', stream => {
      this.setState({ stream })
    })
    peer.on('data', data => {
      const parsed = JSON.parse(data)
      if (parsed.type === 'position') {
        const x = `${Number(parsed.x) * 100}%`
        const y = `${Number(parsed.y) * 100}%`
        if (this.idRef.current) {
          this.idRef.current.style.left = x
          this.idRef.current.style.top = y
        } else {
          this.setState({
            position: { x, y },
          })
        }
      }
    })
  }

  render() {
    return (
      <div className="entity">
        <div
          className="id"
          ref={this.idRef}
          style={{
            left: this.state.position.x,
            top: this.state.position.x,
          }}
        >
          {this.props.peer.peerId.substring(0, 5)}
        </div>
        {this.state.stream && <StreamPlayer stream={this.state.stream} />}
      </div>
    )
  }
}

export default Entity
