import React, { useState } from 'react'

const TouchLayer = ({ onTouchMove }) => {
  const [isMouseDown, setMouseDown] = useState(false)
  return (
    <div
      className="touch-layer"
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => setMouseDown(false)}
      onMouseMove={e => {
        if (isMouseDown) onTouchMove(e)
      }}
      onTouchMove={e => {
        onTouchMove(e)
      }}
    />
  )
}

export default TouchLayer
