import React, { Component, createRef } from 'react'
import socket from 'socket.io-client'
import axios from 'axios'

import ConnectionManager from './ConnectionManager'
import Camera from './Camera'
import StreamPlayer from './StreamPlayer'
import Entity from './Entity'
import TouchLayer from './TouchLayer'

import './index.css'

const ENDPOINT = 'https://simple-peer.moniker.dev'

class TestApp extends Component {
  constructor(props) {
    super(props)

    this.state = {
      peers: [],
      userStream: null,
    }
    this.myId = createRef()

    this.initConnectionManager = this.initConnectionManager.bind(this)

    this.initConnectionManager()

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden' && this.cm) {
        this.cm.destroy()
        this.cm.removeAllListeners()
        this.cm = null
      } else if (document.visibilityState === 'visible') {
        this.initConnectionManager()
      }
    })
  }

  componentDidMount() {}

  componentWillUnmount() {
    if (this.cm) {
      this.cm.destroy()
      this.cm.removeAllListeners()
    }
  }

  async initConnectionManager() {
    if (this.cm) {
      console.log('already has a connection manager tet')
      return
    }
    const io = socket(ENDPOINT)
    const { data: tokens } = await axios.get(`${ENDPOINT}/token`)

    this.cm = new ConnectionManager(io, tokens)
    this.cm.on('rooms', rooms => {
      const room = rooms.pop()
      this.cm.joinRoom(room)
    })
    this.cm.on('connecting', () => {
      this.setState({
        id: io.id,
        connecting: true,
        connected: false,
      })
    })
    this.cm.on('connected', () => {
      this.setState({
        connecting: false,
        connected: true,
      })
    })
    this.cm.on('peer', peer => {
      this.setState({
        peers: [...this.state.peers, peer],
      })
    })
    this.cm.on('disconnected', id => {
      this.setState({
        peers: this.state.peers.filter(peer => peer.peerId !== id),
      })
    })

    if (this.state.userStream) {
      this.cm.setStream(this.state.userStream)
      this.cm.findRooms()
    }
  }

  render() {
    return (
      <div className="App">
        <Camera
          ref={this.cameraRef}
          onCameraInit={({ stream }) => {
            this.setState({
              userStream: stream,
            })

            if (this.cm) {
              this.cm.setStream(stream)
              this.cm.findRooms()
            }
          }}
        />
        <div className="user">
          <div className="id" ref={this.myId}>
            {this.state.id && this.state.id.substring(0, 5)}
          </div>
          {this.state.userStream && <StreamPlayer stream={this.state.userStream} />}
        </div>
        {this.state.peers.map(peer => (
          <Entity key={peer.peerId} peer={peer} />
        ))}
        <div className={this.state.connected ? 'status connected' : 'status disconnected'} />
        <TouchLayer
          onTouchMove={e => {
            const { clientX, clientY } = e.touches ? e.touches[0] : e
            const pX = clientX / window.innerWidth
            const pY = clientY / window.innerHeight
            this.myId.current.style.top = `${clientY - 20}px`
            this.myId.current.style.left = `${clientX - 20}px`
            if (this.cm) {
              this.cm.broadcast({
                type: 'position',
                x: pX.toFixed(4),
                y: pY.toFixed(4),
              })
            }
          }}
        />
      </div>
    )
  }
}

export default TestApp
