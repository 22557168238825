import React, { useRef, useEffect } from 'react'

const StreamPlayer = ({ stream }) => {
  const videoEl = useRef(null)

  useEffect(() => {
    const video = videoEl.current
    if (typeof video.srcObject == 'object') {
      video.srcObject = stream
    } else {
      video.src = URL.createObjectURL(stream)
    }
  }, [stream])

  return <video width={100} ref={videoEl} autoPlay muted playsInline />
}

export default StreamPlayer
