import SimpleSignalClient from 'simple-signal-client'
import { EventEmitter } from 'eventemitter3'

class ConnectionManager extends EventEmitter {
  stream = null
  room = null
  client = null

  constructor(socket, tokens = []) {
    super()

    this.onDiscover = this.onDiscover.bind(this)
    this.findRooms = this.findRooms.bind(this)
    this.joinRoom = this.joinRoom.bind(this)
    this.connect = this.connect.bind(this)
    this.onPeer = this.onPeer.bind(this)
    this.setStream = this.setStream.bind(this)
    this.onRequest = this.onRequest.bind(this)
    this.onPeerDisconnected = this.onPeerDisconnected.bind(this)
    this.onServerMessage = this.onServerMessage.bind(this)
    this.updateConnectionState = this.updateConnectionState.bind(this)

    this.id = socket.id
    this.socket = socket
    this.tokens = tokens
    this.client = new SimpleSignalClient(socket)
    this.lastMessages = {}
    this.peers = {}
    this.isConnected = false

    this.client.on('request', this.onRequest)
    this.client.on('discover', this.onDiscover)

    socket.on('message', this.onServerMessage)
  }

  onServerMessage(message) {
    if (message.removed) {
      this.onPeerDisconnected(message.removed)
    }
  }

  onDiscover(data) {
    if (data.rooms) {
      this.emit('rooms', data.rooms)
    } else if (data.roomResponse && data.roomResponse === this.room) {
      this.emit('connecting', this.room)
      this.peers = {}
      data.peers.forEach(peerId => {
        this.peers[peerId] = {
          isConnecting: true,
          hasStream: false,
          hasData: false,
        }
        this.connect(peerId)
      })
    }
  }

  findRooms() {
    this.client.discover(null)
  }

  joinRoom(roomId) {
    this.room = roomId
    this.client.discover(roomId)
  }

  // connect to another peer
  async connect(peerId) {
    this.updateConnectionState()
    const { peer } = await this.client.connect(peerId, this.room, {
      config: {
        iceServers: this.tokens.slice(0, 2),
        objectMode: true,
      },
    })
    peer.peerId = peerId
    this.onPeer(peer)
    this.updateConnectionState()
  }

  // accept incoming connection
  async onRequest(request) {
    this.updateConnectionState()
    const { peer } = await request.accept()
    peer.peerId = request.initiator
    this.peers[peer.peerId] = {
      isConnecting: true,
      hasStream: false,
      hasData: false,
    }
    this.onPeer(peer)
  }

  onPeer(peer) {
    const id = peer.peerId

    peer.addStream(this.stream)
    peer.on('close', () => {
      console.log('closed', peer)
      this.onPeerDisconnected(id)
    })
    peer.on('error', error => {
      console.error(error)
      this.updateConnectionState()
    })

    // send last state/messages to newly connected peer
    peer.on('connect', () => {
      this.updateConnectionState()
      Object.keys(this.lastMessages).forEach(k => {
        peer.send(JSON.stringify(this.lastMessages[k]))
      })

      this.emit('peer', peer)
    })

    peer.on('stream', () => {
      this.updateConnectionState()
    })
  }

  // TODO: do we need to update all existing streams now?
  setStream(stream) {
    this.stream = stream
  }

  broadcast(message) {
    if (message.type) {
      this.lastMessages[message.type] = message
    }

    this.client.peers().forEach(peer => {
      if (peer._channel.readyState === 'open') {
        peer.send(JSON.stringify(message))
      }
    })
  }

  onPeerDisconnected(peerId) {
    this.updateConnectionState()
    this.emit('disconnected', peerId)
    const toDestroy = this.client.peers().filter(peer => {
      return peer.peerId === peerId
    })
    toDestroy.forEach(peer => peer.destroy())
  }

  updateConnectionState() {
    const prev = this.isConnected
    let current = true
    this.client.peers().forEach(peer => {
      const isConnected =
        peer.connected &&
        peer._remoteStreams.length > 0 &&
        peer._remoteStreams[0].active &&
        peer._channel.readyState === 'open'
      if (!isConnected) current = false
    })

    if (prev !== current) {
      if (current) this.emit('connected', this.room) // connected to all peers
      if (!current) this.emit('connecting', this.room) // missing one or more connectings
    }

    this.isConnected = current
  }

  destroy() {
    this.client.peers().forEach(peer => {
      peer.destroy()
    })
    this.socket.disconnect()

    this.client.removeAllListeners()
    this.socket.removeAllListeners()
  }
}

export default ConnectionManager
