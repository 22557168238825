import { Component } from 'react'
// import PropTypes from 'prop-types'
import 'webrtc-adapter'

// import createCameraConstraints from './createCameraConstraints'
// import detectCameraPermission from '../../../utils/detectCameraPermission'
// import Page from '../../utils/Page'

class Camera extends Component {
  constructor(props) {
    super(props)

    this.initCamera = this.initCamera.bind(this)
    this.handleMediaStream = this.handleMediaStream.bind(this)
    // this.handleOrientationChange = this.handleOrientationChange.bind(this)

    // Page.on('visibilitychange', isVisible => {
    //   if (!isVisible && this.stream) {
    //     this.stream.getTracks().forEach(track => track.stop())
    //     this.stream = undefined
    //   } else if (this.props.isEnabled) {
    //     this.initCamera(this.props.facingMode)
    //   }
    // })
    this.state = {
      isInitialising: false,
    }
  }

  async componentDidMount() {
    this.initCamera(128)
    this.mounted = true
  }

  shouldComponentUpdate() {
    return false
  }

  componentWillUnmount() {
    this.mounted = false
  }

  async initCamera(size) {
    if (!navigator.getUserMedia) throw new Error('No navigator.getUserMedia')
    if (size < 16) throw new Error('Video size should be >= 16')

    this.setState({ isInitialising: true })

    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop())
    }

    // no all browsers allow allow constraints, so we're going to try until one works
    const constraintOptions = [
      {
        audio: false,
        video: true,
      },
    ]
    let option = 0

    // callback function for when we have the result
    const onResult = result => {
      // check if we have a media stream
      if (result instanceof MediaStream) {
        this.handleMediaStream(result)
      } else {
        // try next constraint
        option += 1
        const c = constraintOptions[option]
        if (c) {
          navigator.mediaDevices
            .getUserMedia(c)
            .then(onResult)
            .catch(onResult)
        } else {
          // no more constraints left, throw error
          console.error(result)
          if (this.mounted) this.setState({ isInitialising: false })
        }
      }
    }

    // start with the first constraint
    navigator.mediaDevices
      .getUserMedia(constraintOptions[option])
      .then(onResult)
      .catch(onResult)
  }

  handleMediaStream(stream) {
    this.stream = stream

    const { onCameraInit } = this.props
    if (onCameraInit) {
      onCameraInit({
        stream,
      })
    }
  }

  render() {
    return null
  }
}

export default Camera
